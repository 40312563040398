<template>
    <div class="login">
        <div class="erweima">
            <div class="phone_login">
                <div class="title">手机号注册</div>

                <el-form
                :model="form"
                :rules="rules"
                ref="form_login"
                label-width="100px"
                class="demo-ruleForm"
                >
                <!-- 昵称 -->
                <el-form-item prop="nickname">
                    <el-input
                    type="text"
                    v-model="form.nickname"
                    autocomplete="off"
                    hide-required-asterisk
                    placeholder="请输入昵称"
                    prefix-icon="el-icon-user"
                    >
                    </el-input>
                </el-form-item>

                <!-- 手机号 -->
                <el-form-item prop="phone">
                    <el-input
                    type="text"
                    v-model="form.phone"
                    autocomplete="off"
                    hide-required-asterisk
                    placeholder="请输入手机号"
                    maxlength="11"
                    prefix-icon="el-icon-phone"
                    >
                    </el-input>
                </el-form-item>

                <!-- 验证码 -->
                <el-form-item prop="q_code">
                    <el-input
                    type="text"
                    v-model="form.q_code"
                    placeholder="请输入验证码"
                    autocomplete="off"
                    hide-required-asterisk
                    maxlength="6"
                    prefix-icon="el-icon-message"
                    >
                    </el-input>

                    <el-button
                    type="text"
                    class="get_q_code"
                    :disabled="timeout"
                    @click="get_code"
                    >
                    {{ q_code_msg }}
                    </el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" class="submit_btn" @click="submitForm"
                    >注册</el-button
                    >
                </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { postData } from "@/api/user";
import Cookies from "js-cookie";
import { ElMessage } from "element-plus";

import {toRefs, reactive,ref } from 'vue'
import { useStore } from "vuex"; 
import { useRoute} from "vue-router";

export default {
    name: "login",
    setup() {
        const store = useStore();
        const route = useRoute();
        const form_login = ref(null)

        //检查手机号
        let isCellPhone = (val) => {
            if (!/^1[3456789]\d{9}$/.test(val)) {
                return false;
            } else {
                return true;
            }
        }

        let checkphone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("手机号不能为空"));
            } else if (!isCellPhone(value)) {
                //引入methods中封装的检查手机格式的方法
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        }

        let q_code = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("验证码不能为空"));
            } else if (value.length != 6) {
                //引入methods中封装的检查手机格式的方法
                callback(new Error("验证码为6位数字"));
            } else {
                callback();
            }
        }
    
        let state = reactive({
            id: "",
            form: {
                nickname: route.query.name,
                phone: "",
                q_code: "",
                openid: route.query.openid,
                provider: "qq",
                key: "",
                register_key: "",
                origin_user_info: "",
            },
            rules: {
                nickname: [{ required: true, message: "昵称不能为空", trigger: "blur" },],
                phone: [{ required: true, validator: checkphone, trigger: "blur" }],
                q_code: [{ required: true, validator: q_code, trigger: "change" }],
            },
            q_code_msg: "发送验证码",
            timeout: false,
            time_Interval: "",
            inter_login: "",
            login: "user", // 登录跳转的路径
        })

        // 获取验证码
        let get_code = () => {
            if (state.form.phone.trim() === "") return;

            let count = Cookies.get("phoneCountLimit");
            
            if (Number(count) > 3) {
                ElMessage.warning("每天获取验证码次数不能超过三次");
                return false;
            }

            state.timeout = true;

            let nickname = state.form.nickname.trim();
            let phone = state.form.phone;
            let openid = state.form.openid;
            let provider = state.form.provider;

            postData({
                url: "api/login/sendsmspin",
                data: { nickname, phone, openid, provider },
            }).then((res) => {
                let data = res.data;
                // 倒计时
                let num = 60;
                clearInterval(state.time_Interval);
                state.time_Interval = setInterval(() => {
                    state.q_code_msg = `${num}s`;

                    if (num <= 0) {
                        state.q_code_msg = "重新获取验证码";
                        state.timeout = false;
                        clearInterval(state.time_Interval);
                    }
                    num--;
                }, 1000);

                if (data.code === 200) {
                    state.form.key = data.data.key;
                    state.form.register_key = data.data.pin_code;
                    count_limit();
                } else if (data.code === 422) {
                    ElMessage.warning(data.data.phone[0]);
                    state.q_code_msg = "重新获取验证码";
                    state.timeout = false;
                    clearInterval(state.time_Interval);
                }
            });
        }

        let submitForm = () => {
            form_login.value.validate((valid) => {
                if (valid) {
                    postData({
                        url: "api/login/register",
                        data:{
                            nickname: state.form.nickname.trim(),
                            phone: state.form.phone,
                            code: state.form.q_code,
                            key: state.form.key,
                            register_key: state.form.register_key,
                        },
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            ElMessage.success(data.data.message);
                            store.dispatch('handleQQLogin', data.data)
                        } else if (data.code === 422) {
                            ElMessage.error(data.data.msg);
                        } else {
                            ElMessage.error(data.data.msg);

                        }
                    });
                    } else {
                    return false;
                }
            });
        }

        let count_limit = () => {
            let count = Cookies.get("phoneCountLimit");
            if (count !== null && count !== undefined && count !== "") {
                count = Number(count) + 1;
            } else {
                count = 1;
            }

            Cookies.set("phoneCountLimit", count, { expires: 1 });
        }


        return {
            form_login,
            ...toRefs(state),
            count_limit,
            get_code,
            submitForm,
            name
        }

    }
};
</script>

<style lang="less" scoped>
.login {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 500px;
}

.erweima {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid #e5e5e5;
}

.phone_login {
    .title {
        margin: 34px 0px;
        font-size: 18px;
        font-weight: bold;
        color: #1a1a1a;
    }

    .get_q_code {
        position: absolute;
        top: 0;
        right: 10px;
        font-size: 16px;
        color: #1b4fa8;
    }

    .submit_btn {
        width: 300px;
        height: 46px;
        color: #fff;
        background: #1b4fa8;
    }

    .el-form-item {
        .el-input {
        width: 300px;
        height: 46px;
        input {
            padding-left: 50px;
            height: 100% !important;
        }
        }
    }
}

/deep/.el-form-item__content {
    margin-left: 0px!important;
}
</style>
